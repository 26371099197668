import { CapabilityEnum } from './index';

/**
 * @protobufOption (description) = "Allows a retailer user to manage permissions of another existing subuser"
 * @protobufOption (internalOnly) = false
 * @protobufOption (productArea) = RETAILER_MULTI_USER_MANAGEMENT
 * @protobufOption (title) = "Manage Retailer User Permissions"
 */
export const MANAGE_RETAILER_ACCOUNT_USER_PERMISSIONS =
  'MANAGE_RETAILER_ACCOUNT_USER_PERMISSIONS' as CapabilityEnum;

export const Extensions = {
  productArea: `RETAILER_MULTI_USER_MANAGEMENT`,
  title: `Manage Retailer User Permissions`,
  description: `Allows a retailer user to manage permissions of another existing subuser`,
  internalOnly: false,
};
