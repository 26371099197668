import {
  insiderMembershipState,
  isInsider,
} from "@faire/retailer-shared/lib/retailer";
import { BUSINESS_TYPE } from "@faire/retailer-visitor-shared/consts/BUSINESS_TYPE";
import { DEFAULT_CURRENCY } from "@faire/retailer-visitor-shared/consts/DEFAULT_CURRENCY";
import { AddAddress } from "@faire/retailer-visitor-shared/events";
import { isLoggedInRetailerOrBrandPreview } from "@faire/retailer-visitor-shared/lib/isLoggedInRetailer";
import { isMobileApp } from "@faire/retailer-visitor-shared/lib/isMobileApp";
import { getIsAppInstalled } from "@faire/retailer-visitor-shared/serialized-data/getIsAppInstalled";
import { getIsRobot } from "@faire/retailer-visitor-shared/serialized-data/getIsRobot";
import { getRetailer } from "@faire/retailer-visitor-shared/serialized-data/getRetailer";
import { getSessionCountry } from "@faire/retailer-visitor-shared/serialized-data/getSessionCountry";
import { getShowAmexBanner } from "@faire/retailer-visitor-shared/serialized-data/getShowAmexBanner";
import { getSettingAppMigrationAvailable } from "@faire/retailer-visitor-shared/settings/getSettingAppMigrationAvailable";
import { getSettingEnableSEOLanguageSelector } from "@faire/retailer-visitor-shared/settings/getSettingEnableSEOLanguageSelector";
import { getSettingLanguageSelectorEnabled } from "@faire/retailer-visitor-shared/settings/getSettingLanguageSelectorEnabled";
import { flashSaleSeasonalWarehouseSaleIsOn } from "@faire/retailer-visitor-shared/settings/getSettingSeasonalWarehouseSale";
import { UserStore } from "@faire/retailer-visitor-shared/stores/domain/UserStore";
import { THREE_LETTER_EUROPE_SUPPORTED_COUNTRIES } from "@faire/web--source/common/consts/THREE_LETTER_EUROPE_SUPPORTED_COUNTRIES";
import { THREE_LETTER_PRIMARY_COUNTRY_CODES } from "@faire/web--source/common/consts/THREE_LETTER_PRIMARY_COUNTRY_CODES";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { asInstanceOrUndefined } from "@faire/web--source/common/instanceUtils";
import { makeObservable } from "@faire/web--source/common/makeObservable";
import { getCurrentPath } from "@faire/web--source/common/Path";
import { pathMatchesRoute } from "@faire/web--source/common/routes/RouteMatcher";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { Storage } from "@faire/web--source/common/Storage";
import { createStoreHook } from "@faire/web--source/ui/hooks/useStore";
import getEmailPreferences, {
  QueryParameters as EmailPreferencesParams,
} from "@faire/web-api--source/endpoints/www/api/email/preferences/get";
import getRetailerAsync from "@faire/web-api--source/endpoints/www/api/retailer/retailerToken/get";
import getShowAmexBannerAsync from "@faire/web-api--source/endpoints/www/api/retailer/show-amex-credit-banner/get";
import { IEmailPreferenceGroup } from "@faire/web-api--source/com/faire/email/preferences/IEmailPreferenceGroup";
import { IEmailPreferenceSubgroup } from "@faire/web-api--source/com/faire/email/preferences/IEmailPreferenceSubgroup";
import { IGetEmailPreferencesResponse } from "@faire/web-api--source/com/faire/email/preferences/IGetEmailPreferencesResponse";
import { CurrencyUnit } from "@faire/web-api--source/faire/currency/CurrencyUnit";
import { IFaireMoney } from "@faire/web-api--source/indigofair/data/IFaireMoney";
import { IRetailer } from "@faire/web-api--source/indigofair/data/IRetailer";
import { IRetailerLaunchWithFaire } from "@faire/web-api--source/indigofair/data/IRetailerLaunchWithFaire";
import { IShowAmexCreditBannerResponse } from "@faire/web-api--source/indigofair/data/IShowAmexCreditBannerResponse";
import { PaymentTerm } from "@faire/web-api--source/indigofair/data/PaymentTerm";
import { UserRole } from "@faire/web-api--source/indigofair/data/UserRole";
import { ISO3166Alpha3 } from "@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3";
import { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";
import { route as faireDirectRoute } from "@faire/web-api--source/routes/www/direct/brandAlias";
import { route as faireDirectSalesRepRoute } from "@faire/web-api--source/routes/www/direct/brandAlias/rep/salesRepCode";
import { route as discoverRoute } from "@faire/web-api--source/routes/www/discover/searchTerm";
import { route as exploreRoute } from "@faire/web-api--source/routes/www/explore/searchTerm";
import { route as searchRoute } from "@faire/web-api--source/routes/www/search";
import { addDays } from "date-fns/addDays";
import debug from "debug";
import { action, computed, observable } from "mobx";
import { fromPromise, IPromiseBasedObservable } from "mobx-utils";

const logRetailerStore = debug("IF:RETAILER_STORE");

const DEFAULT_RETURN_WINDOW = 60;

export const LAST_LOGGED_IN_RETAILER_STORAGE_KEY =
  "LAST_TIME_LOGGED_IN_RETAILER_TOKEN";

/**
 * @deprecated
 * Use retailer hooks from '@faire/retailer-visitor-shared/hooks/retailer' instead.
 * You can also create custom hooks that use useRetailer to access retailer information.
 */
export class RetailerStore {
  /**
   * @trackfunction
   */
  static get = singletonGetter(RetailerStore);
  private userStore = UserStore.get();

  @observable
  private _showAmexBanner: IPromiseBasedObservable<IShowAmexCreditBannerResponse> =
    fromPromise(new Promise(() => 0));

  @observable
  private _emailPreferences: IPromiseBasedObservable<IGetEmailPreferencesResponse> =
    fromPromise(new Promise(() => 0));

  constructor() {
    makeObservable(this);
    AddAddress.subscribe(() => {
      this.refetchRetailer(this.retailerToken);
    });
    if (this.retailerToken) {
      Storage.local.setItem(
        LAST_LOGGED_IN_RETAILER_STORAGE_KEY,
        this.retailerToken
      );

      this._emailPreferences = fromPromise(
        getEmailPreferences(
          EmailPreferencesParams.build({
            outgoing_email: UserStore.get().emailAddress,
          })
        ),
        this._emailPreferences
      );
    }
  }

  retailerPromise?: Promise<IRetailer> = undefined;

  @observable
  retailer?: IRetailer = getRetailer();

  refetchRetailer = async (
    retailerToken: string | undefined = this.retailerToken
  ) => {
    if (!retailerToken) {
      return;
    }

    logRetailerStore("Fetching retailer");
    this.retailerPromise = getRetailerAsync(retailerToken);
    const retailer = await this.retailerPromise;
    this.setRetailer(retailer);
    if (this.isValidRetailer) {
      this._showAmexBanner = fromPromise(
        getShowAmexBannerAsync(),
        this._showAmexBanner
      );
    }
  };

  @action
  setRetailer = (retailer?: IRetailer) => {
    if (!retailer && !this.retailer) {
      return;
    }

    if (
      !this.retailer ||
      !retailer ||
      (retailer && retailer.version! > this.retailer.version!)
    ) {
      this.retailer = retailer;
    }
  };

  @computed
  get isValidRetailer(): boolean {
    // Only check for the existence of retailer token is not sufficient because
    // a brand user can also have a retailer token that looks like r_{brandToken}
    return (
      !!this.retailer &&
      !!this.retailer.token &&
      !!this.userStore.user &&
      !!this.userStore.user.roles &&
      this.userStore.user.roles.indexOf(UserRole.RETAILER) !== -1
    );
  }

  @computed
  get retailerToken() {
    return this.retailer?.token;
  }

  @computed
  get retailerName() {
    return this.retailer?.name;
  }

  @computed
  get paymentOnShipmentTerm() {
    return (
      this.paymentOnShipmentTermData?.payment_term ??
      PaymentTerm.PAYMENT_ON_SHIPMENT
    );
  }

  @computed
  private get paymentOnShipmentTermData() {
    return this.retailer?.payment_on_shipment_term_data;
  }

  /**
   * @deprecated
   * Use paymentTermData instead.
   */
  @computed
  get paymentTerm(): keyof typeof PaymentTerm {
    const paymentTerm =
      (this.retailer && this.retailer.payment_term) || PaymentTerm.UNSPECIFIED;
    if (paymentTerm === PaymentTerm.HOLD_ON_PLACEMENT) {
      return PaymentTerm.PAYMENT_ON_SHIPMENT;
    }
    return paymentTerm;
  }

  @computed
  get paymentTermData() {
    return this.retailer?.payment_term_data;
  }

  @computed
  get maximumAvailablePaymentTerm() {
    return (
      (this.retailer && this.retailer.maximum_available_payment_term) ||
      PaymentTerm.UNSPECIFIED
    );
  }

  @computed
  get maximumAvailablePaymentTermData() {
    return this.retailer?.maximum_available_payment_term_data;
  }

  @computed
  get faireCredit() {
    return (this.retailer && this.retailer.available_balance_cents) || 0;
  }

  @computed
  get showFlashSales(): boolean {
    if (!UserStore.get().userCanPlaceOrder) {
      return false;
    }

    return (
      !!this.retailer &&
      !this.retailer.hide_redistribution_channels &&
      (this.isAmerican || this.retailerCountry === undefined)
    );
  }

  /**
   * Whether to couple the fact that we don't show FS/M&M (see above)
   * with a different UI that still exposes entry-points and a sorry message.
   */
  @computed
  get showFlashSalesAsUnavailable(): boolean {
    if (!UserStore.get().userCanPlaceOrder) {
      return false;
    }

    return (
      !!this.retailer?.hide_redistribution_channels &&
      !!this.retailer?.show_flash_sales_mix_and_match_as_unavailable &&
      (this.isAmerican || this.retailerCountry === undefined)
    );
  }

  @computed
  get retailerCountry(): keyof typeof ISO3166Alpha3 | undefined {
    return (
      (this.retailer?.country as ISO3166Alpha3 | undefined) ||
      getSessionCountry()
    );
  }

  @computed
  get isAmerican(): boolean {
    return this.retailerCountry === THREE_LETTER_PRIMARY_COUNTRY_CODES.USA;
  }

  @computed
  get isCanadian(): boolean {
    return this.retailerCountry === THREE_LETTER_PRIMARY_COUNTRY_CODES.CAN;
  }

  @computed
  get isUK(): boolean {
    return this.retailerCountry === THREE_LETTER_PRIMARY_COUNTRY_CODES.GBR;
  }

  @computed
  get isAustralian(): boolean {
    return this.retailerCountry === THREE_LETTER_PRIMARY_COUNTRY_CODES.AUS;
  }

  @computed
  get isEurope(): boolean {
    return THREE_LETTER_EUROPE_SUPPORTED_COUNTRIES.includes(
      this
        .retailerCountry as (typeof THREE_LETTER_EUROPE_SUPPORTED_COUNTRIES)[number]
    );
  }

  @computed
  get isEuropeNonUk(): boolean {
    return !this.isUK && this.isEurope;
  }

  @computed
  get isNewOrMigratedCanadian(): boolean {
    return this.isCanadian && this.retailer?.currency === CurrencyUnit.CAD;
  }

  @computed
  get isInternational(): boolean {
    return !this.isAmerican && !this.isCanadian;
  }

  @computed
  get showLanguageSelector(): boolean {
    // robots should follow showSEOLanguageSelector logic
    return !getIsRobot() && getSettingLanguageSelectorEnabled();
  }

  @computed
  get showSEOLanguageSelector(): boolean {
    /**
     * we want to hide language selector on discover/search and explore page
     * as search terms do not translate well
     * This would also preserve crawl budget
     */
    const blacklist = [
      discoverRoute,
      searchRoute,
      exploreRoute,
      faireDirectRoute,
      faireDirectSalesRepRoute,
    ];
    const hideLanguageSelector = blacklist.some((route) =>
      pathMatchesRoute(getCurrentPath().pathname, route)
    );
    return (
      !!getIsRobot() &&
      !hideLanguageSelector &&
      !isLoggedInRetailerOrBrandPreview() &&
      getSettingEnableSEOLanguageSelector()
    );
  }

  @computed
  get availableLanguageSelectorLocales(): ILocaleKey[] {
    return (
      getGlobalProperty<ILocaleKey[]>("availableLanguageSelectorLocales") ?? []
    );
  }

  @computed
  get currency() {
    return this.retailer?.currency ?? DEFAULT_CURRENCY;
  }

  @computed
  get displayCurrency() {
    return this.currency;
  }

  @computed
  get showR2B(): boolean {
    return !!this.retailer && !this.retailer.hide_retailer2_maker;
  }

  @computed
  get showAmexBanner() {
    return this._showAmexBanner.case({
      pending: (stale: unknown) =>
        asInstanceOrUndefined<IShowAmexCreditBannerResponse>(
          stale,
          "show_banner"
        )?.show_banner ?? getShowAmexBanner(),
      fulfilled: (value) => value.show_banner,
      rejected: () => getShowAmexBanner(),
    });
  }

  @computed
  private get returnWindowDays(): number {
    return this.retailer?.return_window_days ?? DEFAULT_RETURN_WINDOW;
  }

  @computed
  get hasDefaultReturnWindow(): boolean {
    return this.returnWindowDays === DEFAULT_RETURN_WINDOW;
  }

  @computed
  get shouldShowHighReturnerOrderNudges(): boolean {
    return !!this.retailer?.is_high_returner;
  }

  @computed
  get isRetailerPreQualifiedForLimitIncrease(): boolean {
    return this.retailer?.net_terms?.pre_qualified_for_limit_increase ?? false;
  }

  @computed
  get isRetailerOnNetTerms(): boolean {
    return isRetailerOnNetTerms(this.retailer);
  }

  @computed
  get isRetailerQualifiedForTerms(): boolean {
    return isRetailerQualifiedForTerms(this.retailer);
  }

  @computed
  get isNonBrickAndMortarRetailer(): boolean {
    return this.retailer?.business_type !== BUSINESS_TYPE.BRICK_AND_MORTAR_SHOP;
  }

  @computed
  get geographicState(): string | undefined {
    return this.retailer?.address?.state ?? this.userStore.defaultAddress.state;
  }

  @computed
  get isOnlineOnlyRetailer(): boolean {
    return (
      !!this.retailer?.online_only ||
      this.businessCategory === IRetailer.BusinessCategory.ONLINE_ONLY
    );
  }

  @computed
  get reportedCreationTime():
    | keyof typeof IRetailer.ReportedCreationTime
    | undefined {
    return this.retailer?.reported_creation_time;
  }

  @computed
  get reportedMaximumAnnualSales(): IFaireMoney | undefined {
    return this.retailer?.annual_sales_max_amount;
  }

  @computed
  get isPreQualifiedForExpandingOffer(): boolean {
    return this.retailer?.is_pre_qualified_for_expanding_offer ?? false;
  }

  @computed
  get isPreApprovedForExpandingOffer(): boolean {
    return this.retailer?.is_pre_approved_for_expanding_offer ?? false;
  }

  isRetailerSignedUpBefore = (date: Date): boolean => {
    if (this.retailer?.created_at) {
      return date.getTime() >= this.retailer.created_at;
    }
    return false;
  };

  @computed
  get isNewUser(): boolean {
    return !!this.retailer && !this.retailer.first_order_at;
  }

  @computed
  get hasOnlyPlacedFirstOrder(): boolean {
    return (
      !!this.retailer &&
      !!this.retailer.first_order_at &&
      this.retailer.first_order_at === this.retailer.last_order_at
    );
  }

  @computed
  get isRetailerCreatedWithinLast30Days(): boolean {
    if (!this.retailer?.created_at) {
      return false;
    }

    return addDays(new Date(this.retailer?.created_at), 31) > new Date();
  }

  @computed
  get showWarehouseFlashSale(): boolean {
    return !!this.showFlashSales && flashSaleSeasonalWarehouseSaleIsOn();
  }

  @computed
  get insiderMembershipState(): keyof typeof IRetailer.InsiderMembershipState {
    return insiderMembershipState(this.retailer);
  }

  @computed
  get launchWithFaire(): IRetailerLaunchWithFaire | undefined {
    return this.retailer?.launch_with_faire;
  }

  @computed
  get isEligibleForInsider(): boolean {
    return (
      this.insiderMembershipState ===
        IRetailer.InsiderMembershipState.ELIGIBLE ||
      this.insiderMembershipState === IRetailer.InsiderMembershipState.CHURNED
    );
  }

  @computed
  get isInsider(): boolean {
    return isInsider(this.retailer);
  }

  @computed
  get shouldShowInsider() {
    return (
      this.insiderMembershipState !==
      IRetailer.InsiderMembershipState.NOT_ELIGIBLE
    );
  }

  @computed
  get shouldShowInsiderSignupIncentive(): boolean {
    return !!this.retailer?.show_insider_signup_offer;
  }

  @computed
  get showContextualAppDownloadCTAs(): boolean {
    return (
      !getIsAppInstalled() &&
      !isMobileApp() &&
      getSettingAppMigrationAvailable()
    );
  }

  @computed
  get businessCategory() {
    return this.retailer?.business_category;
  }

  @computed
  private get multiUserStates(): Array<keyof typeof IRetailer.MultiUserState> {
    return this.retailer?.multi_user_states ?? [];
  }

  /**
   * Whether this retailer has multiple account users (active or otherwise) as part of the multi-user project.
   */
  @computed
  get hasEverInvitedSubUser(): boolean {
    return this.multiUserStates.includes(
      IRetailer.MultiUserState.HAS_INVITED_SUBUSER
    );
  }

  /**
   * Whether this retailer has any active subusers.
   */
  @computed
  get hasActiveSubUser(): boolean {
    return this.multiUserStates.includes(
      IRetailer.MultiUserState.HAS_ACTIVE_SUBUSER
    );
  }

  /**
   * Whether this retailer has ever had an active subuser.
   */
  @computed
  get hasEverHadActiveSubUser(): boolean {
    return this.multiUserStates.includes(
      IRetailer.MultiUserState.HAS_EVER_HAD_ACTIVE_SUB_USER
    );
  }

  @computed
  private get multiStoreStates(): Array<
    keyof typeof IRetailer.MultiStoreStatus
  > {
    return this.retailer?.multi_store_statuses ?? [];
  }

  @computed
  get hasEverHadMultipleStores(): boolean {
    return this.multiStoreStates.includes(
      IRetailer.MultiStoreStatus.HAS_EVER_HAD_MULTIPLE_STORES
    );
  }

  private isAllEmailPreferenceGroupsUnsubscribed = (
    emailPreferences: IEmailPreferenceGroup[]
  ): boolean => {
    return (
      emailPreferences?.every((emailPreference: IEmailPreferenceGroup) => {
        return (
          emailPreference.subgroups?.every(
            (subgroup: IEmailPreferenceSubgroup) => {
              return subgroup.is_unsubscribed;
            }
          ) ?? false
        );
      }) ?? false
    );
  };

  @computed
  get isUnsubscribedToMarketingEmails(): boolean {
    return this._emailPreferences.case({
      fulfilled: (res) => {
        return (
          !!res.is_unsubscribed_from_all ||
          this.isAllEmailPreferenceGroupsUnsubscribed(
            res.email_preference_groups
          )
        );
      },
    });
  }

  @computed
  get hasPlacedFirstOrder(): boolean {
    return !!this.retailer?.first_order_at;
  }

  @computed
  get isGuestRetailer(): boolean {
    return this.retailer?.is_guest ?? false;
  }
}

/**
 * @deprecated
 * Use retailer hooks from '@faire/retailer-visitor-shared/hooks/retailer' instead.
 * You can also create custom hooks that use useRetailer to access retailer information.
 */
export const useRetailerStore = createStoreHook(RetailerStore);

export const isRetailerOnNetTerms = (retailer: IRetailer | undefined) => {
  return (
    (isRetailerQualifiedForTerms(retailer) &&
      retailer?.maximum_available_payment_term_data?.is_net_terms) ??
    false
  );
};

const isRetailerQualifiedForTerms = (
  retailer: IRetailer | undefined
): boolean => {
  return retailer?.qualified_for_terms ?? false;
};
