import { type IRouteMetadata } from '@faire/web-api--source/Route';
import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';

/**
 * Generated from Jersey path "/explore/{searchTerm:([^/])*}"
 *
 * New page for SEO purposes. The page will have sections linking to /discover pages.
 * @deprecated Redirects to the equivalent discover page
 * Product Area: SEO
 */
export const route = '/explore/:searchTerm([^/]*)';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/explore/{searchTerm:([^/])*}',
  productArea: 'SEO',
  pod: 'FAIRE_POD_RETAILER_ACQUISITION',
  pillar: 'FAIRE_PILLAR_RETAILER_GROWTH',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

/**
 * New page for SEO purposes. The page will have sections linking to /discover pages.
 * @deprecated Redirects to the equivalent discover page
 */
export function path(searchTerm: string) {
  const compiledPath = toPath({
    searchTerm,
  });
  return compiledPath;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (searchTerm: string): string => {
  return faireBaseUrl(3000) + path(searchTerm);
};

export type PathParameters = {
  searchTerm: string;
};

export default path;
