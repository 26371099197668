import { ISO3166Alpha3 } from "@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";

import { getTwoLetterCountryCode } from "../consts/THREE_TO_TWO_LETTER_COUNTRY_ISO_CODE";
import { getTwoLetterLanguageCode } from "../consts/THREE_TO_TWO_LETTER_LANGUAGE_CODE";
import { logError } from "../logging";

interface ILocaleConfig {
  localeKey: ILocaleKey;
  includeCountry?: boolean;
  uppercaseCountry?: boolean;
  lowercaseLanguage?: boolean;
  dashFormat?: boolean;
  localeCountryUrlPrefix?: string;
}

export const localeKeyToLocaleString = ({
  localeKey,
  includeCountry = true,
  uppercaseCountry = true,
  lowercaseLanguage = true,
  dashFormat = true,
  localeCountryUrlPrefix,
}: ILocaleConfig): string => {
  if (localeKey.language === undefined) {
    logError(
      new Error(
        "Cannot convert localeKey with undefined language to locale string"
      )
    );
    return "";
  }

  const languageCode = lowercaseLanguage
    ? `${getTwoLetterLanguageCode(localeKey.language)}`.toLowerCase()
    : `${getTwoLetterLanguageCode(localeKey.language)}`;

  if (
    !includeCountry ||
    (localeKey.country === undefined && localeKey.language !== Language.ENG)
  ) {
    return languageCode;
  }

  //For en-au, the country is AUS
  const localeKeyCountryOrAus =
    localeCountryUrlPrefix === "en-au" ? ISO3166Alpha3.AUS : localeKey.country;

  // At this point the country must be USA if it's not specified
  const country = localeKeyCountryOrAus ?? ISO3166Alpha3.USA;

  const countryCode = uppercaseCountry
    ? `${getTwoLetterCountryCode(country)}`
    : `${getTwoLetterCountryCode(country)}`.toLowerCase();

  return `${languageCode}${dashFormat ? "-" : "_"}${countryCode}`;
};
