import { request } from '@faire/web-api--source';
import { IListRetailerAddressesRequest } from '@faire/web-api--source/indigofair/data/IListRetailerAddressesRequest';
import { IListRetailerAddressesResponse } from '@faire/web-api--source/indigofair/data/IListRetailerAddressesResponse';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/retailer/address/list`;
};

export const build = (
  data: IListRetailerAddressesRequest,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/retailer/address/list';

/**
 * Product Area: RETAILER_ADDRESS_MANAGEMENT
 */
function execute(
  data: IListRetailerAddressesRequest
): Promise<IListRetailerAddressesResponse>;
function execute(
  data: IListRetailerAddressesRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IListRetailerAddressesResponse>>;
function execute(
  data: IListRetailerAddressesRequest,
  options?: EndpointOptions
): Promise<IListRetailerAddressesResponse>;
function execute(
  data: IListRetailerAddressesRequest,
  options?: EndpointOptions
): Promise<
  | IListRetailerAddressesResponse
  | SuccessResponse<IListRetailerAddressesResponse>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<IListRetailerAddressesResponse>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/retailer/address/list/post';
export default execute;
