import { request } from '@faire/web-api--source';
import { IShowAmexCreditBannerResponse } from '@faire/web-api--source/indigofair/data/IShowAmexCreditBannerResponse';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/retailer/show-amex-credit-banner`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/retailer/show-amex-credit-banner';

/**
 * Product Area: AMEX
 */
function execute(): Promise<IShowAmexCreditBannerResponse>;
function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IShowAmexCreditBannerResponse>>;
function execute(
  options?: EndpointOptions
): Promise<IShowAmexCreditBannerResponse>;
function execute(
  options?: EndpointOptions
): Promise<
  IShowAmexCreditBannerResponse | SuccessResponse<IShowAmexCreditBannerResponse>
> {
  const { route, ...requestOptions } = build(options);
  return request<IShowAmexCreditBannerResponse>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/retailer/show-amex-credit-banner/get';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(): Promise<IShowAmexCreditBannerResponse>;
export function darkRead(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IShowAmexCreditBannerResponse>>;
export function darkRead(
  options?: EndpointOptions
): Promise<IShowAmexCreditBannerResponse>;
export function darkRead(
  options?: EndpointOptions
): Promise<
  IShowAmexCreditBannerResponse | SuccessResponse<IShowAmexCreditBannerResponse>
> {
  const { route, ...requestOptions } = build(options);
  return request<IShowAmexCreditBannerResponse>(requestOptions);
}
