import { BrandCartsSortBy } from '@faire/web-api--source/faire/carts/BrandCartsSortBy';
import { IFaireMoney } from '@faire/web-api--source/indigofair/data/IFaireMoney';
import { IItemCustomization } from '@faire/web-api--source/indigofair/data/IItemCustomization';

export namespace IUpdateCartRequestV2 {
  export namespace ICartItemForUpdate {
    /**
     * Return an instance of ICartItemForUpdate with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (
      partial?: Partial<ICartItemForUpdate>
    ): ICartItemForUpdate =>
      ({
        customizations: [],
        ...(partial ?? {}),
      }) as ICartItemForUpdate;
  }

  export interface ICartItemForUpdate {
    /**
     * @protobufOption (.faire.common.tokenType) = CART_ITEM
     */
    token: string | undefined;
    /**
     * @protobufOption (.faire.common.tokenType) = PRODUCT
     */
    product_token: string | undefined;
    product_option_token: string | undefined;
    /**
     * @protobufOption (.faire.common.tokenType) = PRODUCT_COLLECTION
     */
    product_collection_token: string | undefined;
    /**
     * @protobufOption (.faire.common.tokenType) = BRAND
     */
    brand_token: string | undefined;
    quantity: number | undefined;
    customizations: Array<IItemCustomization>;
    /**
     * Deprecated, see BrandCartForUpdate.savedForLater instead.
     * @deprecated
     */
    saved_for_later: boolean | undefined;
    includes_tester: boolean | undefined;
    /**
     * @deprecated
     */
    tester_price: IFaireMoney | undefined;
  }

  export namespace IBrandCartForUpdate {
    /**
     * Return an instance of IBrandCartForUpdate with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (
      partial?: Partial<IBrandCartForUpdate>
    ): IBrandCartForUpdate =>
      ({
        items: [],
        ...(partial ?? {}),
      }) as IBrandCartForUpdate;
  }

  export interface IBrandCartForUpdate {
    /**
     * @deprecated
     * @protobufOption (.faire.common.tokenType) = BRAND_CART
     */
    token: string | undefined;
    /**
     * @protobufOption (.faire.common.tokenType) = BRAND
     */
    brand_token: string | undefined;
    requested_ship_date: number | undefined;
    saved_for_later: boolean | undefined;
    items: Array<IUpdateCartRequestV2.ICartItemForUpdate>;
  }

  /**
   * Return an instance of IUpdateCartRequestV2 with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IUpdateCartRequestV2>
  ): IUpdateCartRequestV2 =>
    ({
      items: [],
      brand_carts: [],
      requested_ship_dates: {},
      filter_brand_carts_by_keys: [],
      ...(partial ?? {}),
    }) as IUpdateCartRequestV2;
}

export interface IUpdateCartRequestV2 {
  items: Array<IUpdateCartRequestV2.ICartItemForUpdate>;
  brand_carts: Array<IUpdateCartRequestV2.IBrandCartForUpdate>;
  requested_ship_dates: Partial<Record<string, number>>;
  /**
   * If true, will return all brand carts in the response instead of just the updated brands.
   */
  return_all_brand_carts: boolean | undefined;
  /**
   * If specified, will return brand carts sorted by the given key.
   */
  sort_brand_carts_by: keyof typeof BrandCartsSortBy | undefined;
  filter_brand_carts_by_keys: Array<string>;
  should_collapse_preorder_groups: boolean | undefined;
}
