import { featureToggle } from "@faire/web--source/common/settings/declarations/featureToggle";

/**
 * XP to migrate the retailer store
 */
export const FE_MIGRATE_RETAILER_STORE_250210 = featureToggle({
  name: "FE_MIGRATE_RETAILER_STORE_250210",
  identifier: "RetailerTokenInSession",
  client: "RETAILER",
  notifyAfter: "2025-05-10",
  defaultValue: false,
  productArea: "RETAILER_ONBOARDING",
});
