import { request } from '@faire/web-api--source';
import { IUser } from '@faire/web-api--source/indigofair/data/IUser';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/user`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/user';

/**
 * Product Area: USERS
 */
function execute(): Promise<IUser>;
function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IUser>>;
function execute(options?: EndpointOptions): Promise<IUser>;
function execute(
  options?: EndpointOptions
): Promise<IUser | SuccessResponse<IUser>> {
  const { route, ...requestOptions } = build(options);
  return request<IUser>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/user/get';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(): Promise<IUser>;
export function darkRead(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IUser>>;
export function darkRead(options?: EndpointOptions): Promise<IUser>;
export function darkRead(
  options?: EndpointOptions
): Promise<IUser | SuccessResponse<IUser>> {
  const { route, ...requestOptions } = build(options);
  return request<IUser>(requestOptions);
}
