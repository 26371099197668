import { CapabilityEnum } from './index';

/**
 * @protobufOption (description) = "Allows a retailer user to place orders"
 * @protobufOption (internalOnly) = false
 * @protobufOption (productArea) = ORDERS
 * @protobufOption (title) = "Place Order"
 */
export const RETAILER_PLACE_ORDER = 'RETAILER_PLACE_ORDER' as CapabilityEnum;

export const Extensions = {
  productArea: `ORDERS`,
  title: `Place Order`,
  description: `Allows a retailer user to place orders`,
  internalOnly: false,
};
