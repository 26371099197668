import { ICartSummaryResponseV2 } from '@faire/web-api--source/faire/carts/ICartSummaryResponseV2';
import { IBrandNudgeTaskUpdated } from '@faire/web-api--source/indigofair/brand/brand_nudge/IBrandNudgeTaskUpdated';
import { IAlertBanner } from '@faire/web-api--source/indigofair/data/IAlertBanner';
import { IBatchOperationUpdated } from '@faire/web-api--source/indigofair/data/IBatchOperationUpdated';
import { IMessengerConversation } from '@faire/web-api--source/indigofair/data/IMessengerConversation';
import { IRetailerUserNotificationCount } from '@faire/web-api--source/indigofair/retailer/IRetailerUserNotificationCount';

export namespace IWebSocketMessage {
  export enum Type {
    ECHO_REQUEST = 'ECHO_REQUEST',
    ECHO_RESPONSE = 'ECHO_RESPONSE',
    CONVERSATION_UPDATED = 'CONVERSATION_UPDATED',
    ALERT_BANNER_UPDATED = 'ALERT_BANNER_UPDATED',
    DRAFT_ORDER_BRAND_EVENT = 'DRAFT_ORDER_BRAND_EVENT',
    EXPRESS_DELIVERY_UPDATED = 'EXPRESS_DELIVERY_UPDATED',
    BATCH_OPERATION_UPDATED = 'BATCH_OPERATION_UPDATED',
    BRAND_NUDGE_TASK_UPDATED = 'BRAND_NUDGE_TASK_UPDATED',
    /**
     * The duties for the retailer's current cart updated, and they should refetch to display the correct amounts.
     */
    CART_DUTIES_UPDATED = 'CART_DUTIES_UPDATED',
    /**
     * The shipping costs for a recent cart summary request has been estimated
     */
    CART_SUMMARY_SHIPPING_COSTS_ESTIMATED = 'CART_SUMMARY_SHIPPING_COSTS_ESTIMATED',
    RETAILER_USER_NOTIFICATION_COUNT_UPDATED = 'RETAILER_USER_NOTIFICATION_COUNT_UPDATED',
    BRAND_IMAGE_NUDGE_GENERATED = 'BRAND_IMAGE_NUDGE_GENERATED',
    BRAND_LISTING_NUDGE_GENERATED = 'BRAND_LISTING_NUDGE_GENERATED',
    BRAND_UPDATED_TO_REVIEW_STATE = 'BRAND_UPDATED_TO_REVIEW_STATE',
    BRAND_RECEIVED_FIRST_ORDER = 'BRAND_RECEIVED_FIRST_ORDER',
  }

  /**
   * Return an instance of IWebSocketMessage with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IWebSocketMessage>
  ): IWebSocketMessage =>
    ({
      ...(partial ?? {}),
    }) as IWebSocketMessage;
}

export interface IWebSocketMessage {
  type: keyof typeof IWebSocketMessage.Type | undefined;
  echo_string: string | undefined;
  updated_conversation: IMessengerConversation | undefined;
  alert_banner: IAlertBanner | undefined;
  batch_operation_updated: IBatchOperationUpdated | undefined;
  brand_nudge_task_updated: IBrandNudgeTaskUpdated | undefined;
  cart_summary_shipping_costs_estimated: ICartSummaryResponseV2 | undefined;
  retailer_user_notification_count: IRetailerUserNotificationCount | undefined;
}
