import { IToken } from '@faire/web-api--source/faire/common/IToken';
import { FairePillar } from '@faire/web-api--source/faire/logging/FairePillar';
import { FairePod } from '@faire/web-api--source/faire/logging/FairePod';
import { ProductArea } from '@faire/web-api--source/faire/logging/ProductArea';
import { Validation } from '@faire/web-api--source/faire/protobuf/Validation';

export namespace ISettingEntry {
  export enum SettingType {
    SETTING_TYPE_UNKNOWN = 'SETTING_TYPE_UNKNOWN',
    CONFIGURATION = 'CONFIGURATION',
    EXPERIMENT = 'EXPERIMENT',
    FEATURE_TOGGLE = 'FEATURE_TOGGLE',
  }

  export enum SerializeToClient {
    SERIALIZE_TO_CLIENT_UNKNOWN = 'SERIALIZE_TO_CLIENT_UNKNOWN',
    /**
     * Retailer web portal
     */
    RETAILER = 'RETAILER',
    /**
     * Retailer web portal, but only if the auth'd user is logged in
     */
    LOGGED_IN_RETAILER = 'LOGGED_IN_RETAILER',
    /**
     * Legacy neighborhood retailer portal
     * @deprecated
     */
    RETAILER_PORTAL = 'RETAILER_PORTAL',
    /**
     * Brand web portal
     */
    MAKER = 'MAKER',
    /**
     * Legacy neighborhood consumer portal
     * @deprecated
     */
    CONSUMER = 'CONSUMER',
    /**
     * admin2 web
     */
    ADMIN = 'ADMIN',
    /**
     * Help center web
     */
    HELP_CENTER = 'HELP_CENTER',
    /**
     * Brand widget web
     */
    BRAND_WIDGET = 'BRAND_WIDGET',
    /**
     * Retailer android app
     */
    RETAILER_ANDROID = 'RETAILER_ANDROID',
    /**
     * Retailer ios app
     */
    RETAILER_IOS = 'RETAILER_IOS',
    /**
     * Brand android app
     */
    BRAND_ANDROID = 'BRAND_ANDROID',
    /**
     * Brand ios app
     */
    BRAND_IOS = 'BRAND_IOS',
    /**
     * Visitor (logged-out) React app.
     */
    VISITOR_WEB = 'VISITOR_WEB',
    /**
     * Shopify Sales Channel app
     */
    SHOPIFY_SALES_CHANNEL = 'SHOPIFY_SALES_CHANNEL',
    /**
     * hotjar for brand web
     */
    HOTJAR_BRAND = 'HOTJAR_BRAND',
    /**
     * hotjar for retailer web
     */
    HOTJAR_RETAILER = 'HOTJAR_RETAILER',
    /**
     * hotjar for help center
     */
    HOTJAR_HELP_CENTER = 'HOTJAR_HELP_CENTER',
    /**
     * Retailer Shopify Integration app
     */
    RETAILER_SHOPIFY_INTEGRATION = 'RETAILER_SHOPIFY_INTEGRATION',
    /**
     * Edge server
     */
    EDGE = 'EDGE',
    /**
     * Shopify Product Sourcing app (will replace RETAILER_SHOPIFY_INTEGRATION)
     */
    SHOPIFY_PRODUCT_SOURCING = 'SHOPIFY_PRODUCT_SOURCING',
    /**
     * Head tags for retailer web
     */
    RETAILER_HEAD_TAGS = 'RETAILER_HEAD_TAGS',
    /**
     * Our beloved dev.faire.team
     */
    DEV_PORTAL = 'DEV_PORTAL',
    /**
     * Our own PR bot
     */
    PR_BOT = 'PR_BOT',
    VISITOR_WEB_HEAD_TAGS = 'VISITOR_WEB_HEAD_TAGS',
    HOTJAR_VISITOR_WEB = 'HOTJAR_VISITOR_WEB',
  }

  /**
   * Return an instance of ISettingEntry with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<ISettingEntry>): ISettingEntry =>
    ({
      serialize_to_pages: [],
      monitor_client_performance: [],
      ...(partial ?? {}),
    }) as ISettingEntry;
}

export interface ISettingEntry {
  name: string | undefined;
  /**
   * Type of the value used by the setting, e.g. "String" or "org.joda.time.DateTime"
   */
  type: string | undefined;
  /**
   * Setting entry type (if annotated as Configuration, FeatureToggle, or Experiment).
   */
  setting_entry_type: keyof typeof ISettingEntry.SettingType | undefined;
  /**
   * Whether the setting is "public" (user-facing) or not.
   */
  public: boolean | undefined;
  /**
   * Docs for the setting.
   */
  docs: string | undefined;
  /**
   * Whether the setting is marked @Deprecated
   */
  deprecated: boolean | undefined;
  /**
   * Comment explaining the deprecation annotation, if any.
   */
  deprecated_comment: string | undefined;
  /**
   * Realms that the setting's value is serialized to the page (e.g. RETAILER)
   */
  serialize_to_pages: Array<keyof typeof ISettingEntry.SerializeToClient>;
  notify_policy: string | undefined;
  validation_type: keyof typeof Validation | undefined;
  token_type: keyof typeof IToken.Type | undefined;
  product_area: keyof typeof ProductArea | undefined;
  pod: keyof typeof FairePod | undefined;
  pillar: keyof typeof FairePillar | undefined;
  testable: boolean | undefined;
  /**
   * Home resource types (e.g. RETAILER) for which the value of the setting should be emitted into
   * the client-side performance metrics, as a custom attribute, so that we can track its impact.
   */
  monitor_client_performance: Array<
    keyof typeof ISettingEntry.SerializeToClient
  >;
  /**
   * Source class of the setting definition.
   */
  source_class: string | undefined;
  /**
   * True if this setting is defined using a legacy FeatureSetting enum definition.
   */
  is_enum: boolean | undefined;
  /**
   * Name of the instance of [SettingIdentifierType] that this setting uses.
   */
  setting_identifier_type: string | undefined;
}
