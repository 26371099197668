import { CapabilityEnum } from './index';

/**
 * @protobufOption (description) = "Can request transferring ownership of a retailer account from one user to another user."
 * @protobufOption (internalOnly) = false
 * @protobufOption (productArea) = RETAILER_OWNER_TRANSFER
 * @protobufOption (title) = "Transfer Retailer Ownership"
 */
export const RETAILER_TRANSFER_OWNERSHIP =
  'RETAILER_TRANSFER_OWNERSHIP' as CapabilityEnum;

export const Extensions = {
  productArea: `RETAILER_OWNER_TRANSFER`,
  title: `Transfer Retailer Ownership`,
  description: `Can request transferring ownership of a retailer account from one user to another user.`,
  internalOnly: false,
};
