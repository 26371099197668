import { BUSINESS_TYPE } from "@faire/retailer-visitor-shared/consts/BUSINESS_TYPE";
import { useRetailer } from "@faire/retailer-visitor-shared/hooks/retailer/useRetailer";
import { useSettingMigrateRetailerStore } from "@faire/retailer-visitor-shared/settings/useSettingMigrateRetailerStore";
import { useRetailerStore } from "@faire/retailer-visitor-shared/stores/domain/RetailerStore";
import { IRetailer } from "@faire/web-api--source/indigofair/data/IRetailer";

const useRetailerTypeControl = () => {
  const {
    isNewUser,
    isGuestRetailer,
    isOnlineOnlyRetailer,
    isNonBrickAndMortarRetailer,
  } = useRetailerStore([
    "isNewUser",
    "isGuestRetailer",
    "isOnlineOnlyRetailer",
    "isNonBrickAndMortarRetailer",
  ]);

  return {
    isNewUser,
    isGuestRetailer,
    isOnlineOnlyRetailer,
    isNonBrickAndMortarRetailer,
  };
};

const useRetailerTypeTreatment = () => {
  const { retailer } = useRetailer();

  const isNewUser = !!retailer && !retailer.first_order_at;
  const isGuestRetailer = retailer?.is_guest ?? false;
  const isOnlineOnlyRetailer =
    !!retailer?.online_only ||
    retailer?.business_category === IRetailer.BusinessCategory.ONLINE_ONLY;
  const isNonBrickAndMortarRetailer =
    retailer?.business_type !== BUSINESS_TYPE.BRICK_AND_MORTAR_SHOP;

  return {
    isNewUser,
    isGuestRetailer,
    isOnlineOnlyRetailer,
    isNonBrickAndMortarRetailer,
  };
};

export const useRetailerType = () => {
  const shouldUseHooksMigration = useSettingMigrateRetailerStore();
  if (shouldUseHooksMigration) {
    // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
    return useRetailerTypeTreatment();
  }
  // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
  return useRetailerTypeControl();
};
