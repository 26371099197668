import { getSettingValueWithoutAssignment } from "@faire/retailer-visitor-shared/settings/__internal__/getSetting";
import { SEASONAL_WAREHOUSE_SALE_ENABLED } from "@faire/web-api--source/indigofair/settings/SEASONAL_WAREHOUSE_SALE_ENABLED";
import { SEASONAL_WAREHOUSE_SALE_END_AT } from "@faire/web-api--source/indigofair/settings/SEASONAL_WAREHOUSE_SALE_END_AT";
import { SEASONAL_WAREHOUSE_SALE_NAME } from "@faire/web-api--source/indigofair/settings/SEASONAL_WAREHOUSE_SALE_NAME";
import { SEASONAL_WAREHOUSE_SALE_START_AT } from "@faire/web-api--source/indigofair/settings/SEASONAL_WAREHOUSE_SALE_START_AT";
import { parseISO as parseDate } from "date-fns/parseISO";

export const getSettingSeasonalWarehouseName = (): string =>
  getSettingValueWithoutAssignment(
    SEASONAL_WAREHOUSE_SALE_NAME,
    "Seasonal warehouse sale"
  );

const getSettingSeasonalWarehouseSaleEnabled = (): boolean =>
  getSettingValueWithoutAssignment(SEASONAL_WAREHOUSE_SALE_ENABLED, false);

const getSettingSeasonalWarehouseSaleEndAt = (): number =>
  getSettingValueWithoutAssignment(
    SEASONAL_WAREHOUSE_SALE_END_AT,
    parseDate("Febuary 2030").getTime()
  );

const getSettingSeasonalWarehouseSaleStartAt = (): number =>
  getSettingValueWithoutAssignment(
    SEASONAL_WAREHOUSE_SALE_START_AT,
    parseDate("January 2030").getTime()
  );

export const flashSaleSeasonalWarehouseSaleHasEnded = (): boolean =>
  getSettingSeasonalWarehouseSaleEndAt() < Date.now();

export const flashSaleSeasonalWarehouseSaleIsOn = (): boolean => {
  const now = Date.now();
  return (
    getSettingSeasonalWarehouseSaleStartAt() < now &&
    now < getSettingSeasonalWarehouseSaleEndAt() &&
    getSettingSeasonalWarehouseSaleEnabled()
  );
};
