import { useRetailer } from "@faire/retailer-visitor-shared/hooks/retailer/useRetailer";
import { useRetailerType } from "@faire/retailer-visitor-shared/hooks/retailer/useRetailerType";
import {
  getIsBrandPreview,
  useIsBrandPreview,
} from "@faire/retailer-visitor-shared/lib/getIsBrandPreview";
import { getRetailer } from "@faire/retailer-visitor-shared/serialized-data/getRetailer";
import {
  UserStore,
  useUserStore,
} from "@faire/retailer-visitor-shared/stores/domain/UserStore";

/**
 * @deprecated Use `useIsLoggedInRetailer` instead
 */
export const isLoggedInRetailer = (): boolean =>
  !!UserStore.get().userToken &&
  !!getRetailer() &&
  !getRetailer()?.is_guest &&
  !getIsBrandPreview();

export const useIsLoggedInRetailer = (): boolean => {
  const { userToken } = useUserStore(["userToken"]);
  const { retailerToken } = useRetailer();
  const { isGuestRetailer } = useRetailerType();
  const isBrandPreview = useIsBrandPreview();
  return !!userToken && !!retailerToken && !isBrandPreview && !isGuestRetailer;
};

export const useIsLoggedInRetailerOrBrandPreview = (): boolean => {
  const isLoggedInRetailer = useIsLoggedInRetailer();
  const isBrandPreview = useIsBrandPreview();
  return isLoggedInRetailer || isBrandPreview;
};

/**
 * @deprecated Use `useIsLoggedInRetailerOrBrandPreview` instead
 */
export const isLoggedInRetailerOrBrandPreview = (): boolean =>
  isLoggedInRetailer() || getIsBrandPreview();
