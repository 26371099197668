import { getRetailer } from "@faire/retailer-visitor-shared/serialized-data/getRetailer";
import { useSettingMigrateRetailerStore } from "@faire/retailer-visitor-shared/settings/useSettingMigrateRetailerStore";
import { useRetailerStore } from "@faire/retailer-visitor-shared/stores/domain/RetailerStore";
import {
  useQuery as useRetailerQuery,
  getQueryKey as getRetailerQueryKey,
} from "@faire/web-api--source/endpoints/www/api/retailer/retailerToken/get-hooks";
import { IRetailer } from "@faire/web-api--source/indigofair/data/IRetailer";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

const useRetailerControl = () => {
  const {
    retailer,
    retailerToken,
    refetchRetailer,
    retailerName,
    retailerPromise,
    setRetailer,
  } = useRetailerStore([
    "retailer",
    "retailerToken",
    "refetchRetailer",
    "retailerName",
    "retailerPromise",
    "setRetailer",
  ]);

  return {
    retailer,
    retailerToken,
    refetchRetailer,
    retailerName,
    retailerPromise,
    setRetailer,
  };
};

const useRetailerTreatment = () => {
  const serializedRetailer = getRetailer();

  // TODO: need to refetch showAmexBanner after on retailer refetch
  const { data: retailer, refetch: refetchRetailer } = useRetailerQuery(
    serializedRetailer?.token ?? "",
    undefined,
    {
      enabled: !!serializedRetailer?.token,
      initialData: serializedRetailer,
      refetchOnMount: false,
      staleTime: Infinity,
      gcTime: Infinity,
    }
  );
  const retailerName = retailer?.name;
  const retailerToken = retailer?.token;

  const queryClient = useQueryClient();
  const setRetailer = useCallback(
    (retailer: IRetailer | undefined) => {
      queryClient.setQueryData<IRetailer | undefined>(
        getRetailerQueryKey(retailerToken ?? ""),
        retailer
      );
    },
    [queryClient, retailerToken]
  );

  return {
    retailer,
    retailerToken,
    refetchRetailer,
    retailerName,
    setRetailer,
  };
};

export const useRetailer = () => {
  const shouldUseHooksMigration = useSettingMigrateRetailerStore();
  if (shouldUseHooksMigration) {
    // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
    return useRetailerTreatment();
  }
  // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
  return useRetailerControl();
};
